import { actions, subjects } from "@/libs/acl/rules";

export default [
  {
    title: "Painel",
    route: "home",
    resource: subjects.HOME_MENUS_ROTAS,
    action: actions.VISUALIZAR,
    icon: "dashboard.svg",
    noChildren: true
  },
  {
    header: "CAMPANHA",
    icon: "HomeIcon",
    action: actions.VISUALIZAR,
    resource: subjects.GERAL,
  },
  {
    title: "Campanhas",
    icon: "check.svg",
    children: [
      {
        title: "Cadastrar nova",
        route: "register-campanha",
        resource: subjects.CAMPANHAS_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
      {
        title: "Ver Campanhas",
        route: "campanha-list",
        resource: subjects.CAMPANHAS_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Termo de adesão",
        route: "adhesion-contract",
        resource: subjects.CAMPANHA_TERMO_DR,
        action: actions.GERENCIAR,
      },
    ],
  },
  {
    title: "Base Nacional",
    icon: "national.svg",
    children: [
      {
        title: "Grupo de empresas",
        route: "group-company-list",
        resource: subjects.TIPO_ATUACAO_GRUPO_EMPRESA,
        action: actions.VISUALIZAR
      },
      {
        title: "Criar grupo empresa",
        route: "create-company-group",
        resource: subjects.TIPO_ATUACAO_GRUPO_EMPRESA,
        action: actions.VISUALIZAR,
        hidden: true,
      },
      {
        title: "Editar grupo empresa",
        route: "edit-company-group",
        resource: subjects.TIPO_ATUACAO_GRUPO_EMPRESA,
        action: actions.VISUALIZAR,
        hidden: true,
      },
      {
        title: "Visualizar grupo empresa",
        route: "view-company-group",
        resource: subjects.TIPO_ATUACAO_GRUPO_EMPRESA,
        action: actions.VISUALIZAR,
        hidden: true,
      },
      {
        title: "Campanha Nacional",
        route: "campanha-list-nacional",
        resource: subjects.TIPO_ATUACAO_CAMPANHA_BASE_NACIONAL_COORDENADOR,
        action: actions.VISUALIZAR,
      },
      {
        title: "Adesão nacional",
        route: "listar-adesao-nacional",
        resource: subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL,
        action: actions.VISUALIZAR,
      },
    ]
  },
  {
    title: "Adesão",
    icon: "add-circle.svg",
    children: [
      {
        title: "Ver Pedidos",
        route: "acceptances",
        resource: subjects.ADESAO_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Aderir Campanha",
        route: "join-campaign",
        resource: subjects.ADESAO_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Adesão",
        route: "purchase",
        resource: subjects.ADESAO_MENUS_ROTAS,
        action: actions.VISUALIZAR,
        hidden: true,
      },
    ],
  },
  {
    title: "Agendamento",
    icon: "event-note.svg",
    children: [
      {
        title: "Ver Agendamentos",
        route: "agendamento-list",
        resource: subjects.AGENDAMENTO_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Cadastrar novo",
        route: "agendamento-register",
        resource: subjects.AGENDAMENTO_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
    ],
  },
  {
    title: "Estoque",
    icon: "package.svg",
    children: [
      {
        title: "Estoque físico",
        route: "estoque-list",
        resource: subjects.ESTOQUE_FISICO_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Entrada",
        route: "entrada-list",
        resource: subjects.ESTOQUE_ENTRADA_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Visualizar Entrada",
        route: "entrada-show",
        resource: subjects.ESTOQUE_ENTRADA_MENUS_ROTAS,
        action: actions.VISUALIZAR,
        hidden: true,
      },
      {
        title: "Transferência",
        route: "transferencia-list",
        resource: subjects.ESTOQUE_TRANSFERENCIA_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Visualizar Transferência",
        route: "transferencia-show",
        resource: subjects.ESTOQUE_TRANSFERENCIA_MENUS_ROTAS,
        action: actions.VISUALIZAR,
        hidden: true
      },
      {
        title: "Editar Transferência",
        route: "transferencia-edit",
        resource: subjects.ESTOQUE_TRANSFERENCIA_MENUS_ROTAS,
        action: actions.VISUALIZAR,
        hidden: true
      },
      {
        title: "Ajuste de Estoque",
        route: "ajuste-estoque-list",
        resource: subjects.ESTOQUE_AJUSTE_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Visualizar Ajuste de Estoque",
        route: "ajuste-estoque-show",
        resource: subjects.ESTOQUE_AJUSTE_MENUS_ROTAS,
        action: actions.VISUALIZAR,
        hidden: true
      },
    ],
  },
  {
    title: "Registro Vacinados",
    icon: "description.svg",
    children: [
      {
        title: "Ver Registro",
        route: "vacinacao-list",
        resource: subjects.REGISTRO_VACINACAO_SESI_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Cadastrar novo",
        route: "empresa-vacinacao-select",
        resource: subjects.REGISTRO_VACINACAO_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
      {
        title: "Lista registro vacinação",
        route: "registro-vacinacao-list",
        resource: subjects.REGISTRO_VACINACAO_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
    ],
  },
  {
    header: "SESI",
    icon: "HomeIcon",
    action: actions.VISUALIZAR,
    resource: subjects.PONTO_ATENDIMENTO_SESI_MENUS_ROTAS,
  },
  {
    title: "Dep. Regionais",
    icon: "map.svg",
    children: [
      {
        title: "Ver Departamentos",
        route: "departamento-list",
        resource: subjects.DEPARTAMENTO_SESI_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Cadastrar novo",
        route: "departamento-register",
        resource: subjects.DEPARTAMENTO_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
      {
        title: "Editar Departamento",
        route: "departamento-edit",
        resource: subjects.DEPARTAMENTO_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
      {
        title: "Visualizar Departamento",
        route: "departamento-view",
        resource: subjects.DEPARTAMENTO_SESI_MENUS_ROTAS,
        action: actions.VISUALIZAR,
        hidden: true,
      },
    ],
  },
  {
    title: "Uni. Operacionais",
    icon: "location.svg",
    children: [
      {
        title: "Ver Unidades",
        route: "unidade-list",
        resource: subjects.UNIDADE_SESI_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Cadastrar Novo",
        route: "unidade-register",
        resource: subjects.UNIDADE_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
      {
        title: "Editar unidade",
        route: "unidade-edit",
        resource: subjects.UNIDADE_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
      {
        title: "Visualizar unidade",
        route: "unidade-view",
        resource: subjects.UNIDADE_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
    ],
  },
  {
    title: "Pt. de Atendimento",
    icon: "flag.svg",
    children: [
      {
        title: "Ver pontos",
        route: "ponto-atendimento-list",
        resource: subjects.PONTO_ATENDIMENTO_SESI_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Cadastrar Novo",
        route: "ponto-atendimento-register",
        resource: subjects.PONTO_ATENDIMENTO_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
      {
        title: "Editar Ponto Atendimento",
        route: "ponto-atendimento-edit",
        resource: subjects.PONTO_ATENDIMENTO_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
      {
        title: "Visualizar Ponto Atendimento",
        route: "ponto-atendimento-view",
        resource: subjects.PONTO_ATENDIMENTO_SESI_MENUS_ROTAS,
        action: actions.VISUALIZAR,
        hidden: true,
      },
    ],
  },
  {
    header: "GERENCIAMENTO",
    icon: "HomeIcon",
    action: actions.VISUALIZAR,
    resource: subjects.GERAL,
  },
  {
    title: "Empresas",
    icon: "building.svg",
    resource: subjects.EMPRESAS_MENUS_ROTAS,
    action: actions.VISUALIZAR,
    children: [
      {
        title: "Ver Empresas",
        route: "empresa-list",
        resource: subjects.EMPRESAS_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Cadastrar Nova",
        route: "empresa-register",
        resource: subjects.EMPRESAS_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
      {
        title: "Editar empresa",
        route: "empresa-edit",
        resource: subjects.EMPRESAS_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
      {
        title: "Mostrar empresa",
        route: "empresa-show",
        resource: subjects.EMPRESAS_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
    ],
  },
  {
    title: "Relatórios",
    icon: "bars.svg",
    children: [
      {
        title: "Exportação SMD",
        route: "export-smd",
        resource: subjects.RELATORIO_SMD_MENU_ROTAS_VISUALIZAR,
        action: actions.VISUALIZAR,
      },
      {
        title: "Exportação Relatórios",
        route: "export-relatorios",
        resource: subjects.RELATORIO_EXPORTACAO_MENU_ROTAS_VISUALIZAR,
        action: actions.VISUALIZAR,
      },
    ],
  },
  {
    title: "Gerenciar usuários",
    icon: "users.svg",
    children: [
      {
        title: "Ver usuários",
        route: "usuario-list",
        resource: subjects.USUARIO_SESI_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Cadastrar novo",
        route: "usuario-register",
        resource: subjects.USUARIO_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
      {
        title: "Visualizar usuário",
        route: "usuario-view",
        resource: subjects.USUARIO_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
      {
        title: "Editar usuário",
        route: "usuario-edit",
        resource: subjects.USUARIO_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
    ],
  },
  {
    title: "Meu perfil",
    icon: "settings.svg",
    verificadorLogin: true,
    resource: subjects.DEPARTAMENTO,
    action: actions.VISUALIZAR,
    children: [
      {
        title: "Ver Dados",
        route: "departamento-view-regiao",
        resource: subjects.DEPARTAMENTO,
        action: actions.VISUALIZAR,
      },
      {
        title: "Editar Departamento Região",
        route: "departamento-edit-regiao",
        resource: subjects.DEPARTAMENTO,
        action: actions.VISUALIZAR,
        hidden: true,
      },
    ],
  },
  {
    title: "Avisos",
    icon: "notification.svg",
    children: [
      {
        title: "Ver avisos",
        route: "aviso-list",
        resource: subjects.AVISOS_SESI_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Cadastrar novo",
        route: "aviso-register",
        resource: subjects.AVISOS_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
      {
        title: "Editar doença",
        route: "aviso-edit",
        resource: subjects.AVISOS_SESI_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
      {
        title: "Mostrar aviso",
        route: "aviso-show",
        resource: subjects.AVISOS_SESI_MENUS_ROTAS,
        action: actions.VISUALIZAR,
        hidden: true,
      },
    ],
  },
  {
    title: "Doenças",
    icon: "cardiology.svg",
    children: [
      {
        title: "Ver doenças",
        route: "doenca-list",
        resource: subjects.DOENCA_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Cadastrar nova",
        route: "doenca-register",
        resource: subjects.DOENCA_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
      {
        title: "Editar doença",
        route: "doenca-edit",
        resource: subjects.DOENCA_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
      {
        title: "Mostrar doença",
        route: "doenca-show",
        resource: subjects.DOENCA_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      }
    ],
  },
  {
    title: "Vacinas",
    icon: "syringe.svg",
    children: [
      {
        title: "Ver vacinas",
        route: "vacina-list",
        resource: subjects.VACINA_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Cadastrar nova",
        route: "vacina-register",
        resource: subjects.VACINA_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
      {
        title: "Editar vacina",
        route: "vacina-edit",
        resource: subjects.VACINA_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
      {
        title: "Mostrar vacina",
        route: "vacina-show",
        resource: subjects.VACINA_MENUS_ROTAS,
        action: actions.GERENCIAR,
        hidden: true,
      },
    ],
  },
  {
    title: "Ajuda e suporte",
    icon: "help.svg",
    children: [
      {
        title: "Ver tópicos",
        route: "ajuda-list",
        resource: subjects.AJUDA_SUPORTE_MENUS_ROTAS,
        action: actions.VISUALIZAR,
      },
      {
        title: "Cadastrar novo",
        route: "ajuda-register",
        resource: subjects.AJUDA_SUPORTE_MENUS_ROTAS,
        action: actions.GERENCIAR,
      },
    ],
  },
  {
    title: "Suporte",
    icon: "ListIcon",
    children: [
      {
        title: "Jobs com falha",
        route: "jobs-com-falha-list",
        resource: subjects.SUPORTE_GERENCIAMENTO_FILA,
        action: actions.VISUALIZAR,
      },
      {
        title: "Limpeza de cache regras",
        route: "limpeza-cache-regras-list",
        resource: subjects.SUPORTE_LIMPEZA_CACHE,
        action: actions.GERENCIAR,
      },
      {
        title: "Usuários Integração",
        route: "usuarios-integracao-list",
        resource: subjects.SUPORTE_GERENCIAMENTO_USUARIOS_INTEGRACOES,
        action: actions.VISUALIZAR,
      },
      {
        title: "Pessoa Física",
        route: "pessoa-fisica-list",
        resource: subjects.SUPORTE_GERENCIAMENTO_PESSOA_FISICA,
        action: actions.VISUALIZAR,
      },
    ],
  },
];
