<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': !item.noChildren ? isActive : false,
      'disabled': item.disabled,
      'open': item.noChildren ? isActive : false,
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <img v-if="customIcon" :src="customIcon" class="mr-1" alt="icone customizado">
      <feather-icon v-else-if="isActive" 
      icon="CircleIcon" 
      style="margin-left: 6px!important;"
      class="m-0 text-white mr-2 animate__animated animate__bounceIn"/>
      <img v-else :src="ImageUrl" class="mr-1" alt="icone customizado">
      <span v-if="!isVerticalMenuCollapsed" 
      class="menu-title text-truncate"
      :class="{
        'font-weight-bolder':isActive
      }"
      >{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import ImageUrl from  '@/assets/custom-icons/menu/dot.svg'
import useVerticalNavMenu from '../../useVerticalNavMenu'
import 'animate.css';

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()
    const {
      isVerticalMenuCollapsed
    } = useVerticalNavMenu(props)

    let customIcon = false; 

    if(props.item.icon) {
      customIcon = require('@/assets/custom-icons/menu/' + props.item.icon);
    }



    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
      ImageUrl,
      customIcon,
      isVerticalMenuCollapsed
    }
  }

}
</script>
