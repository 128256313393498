<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow bg-custom-blue"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >

  <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row justify-content-left mt-1">

          <!-- Logo & Text -->
          <li class="nav-item d-flex justify-content-left ml-1">
            <div class="box-img-nav ml-2"
            v-if="!isVerticalMenuCollapsed"
            >
              <img
                  :src="appLogoImage"
                  alt="logo"
              />

            </div>
            <div v-else class="d-flex">
              <img
                  :src="vLogo"
                  class="mr-5px"
                  alt="logo"
              />
              <img :src="isVerticalMenuCollapsed ? arrowRight : arrowLeft"
                alt="arrow-left"
                class="text-light cursor-pointer"
                @click="toggleCollapsed"
              >
            </div>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle d-block d-xl-none">
            <b-link class="nav-link ml-1">
              <feather-icon
                icon="XIcon"
                size="20"
                class=" text-light"
                @click="toggleVerticalMenuActive"
              />
            </b-link>
          </li>
          <li class="nav-item nav-toggle d-none d-xl-block ml-1">
            <b-link class="nav-link modern-nav-toggle m-0">
              <img :src="isVerticalMenuCollapsed ? arrowRight : arrowLeft"
                alt="arrow-left"
                class="text-light cursor-pointer"
                @click="toggleCollapsed"
              >
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :key="update"
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import arrowLeft from  '@/assets/custom-icons/menu/arrow-left.svg'
import arrowRight from  '@/assets/custom-icons/menu/arrow-right.svg'
import vLogo from  '@/assets/custom-icons/menu/v-logo.svg'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
      toggleCollapsedWithVal
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    window.addEventListener('resize', function() {
      toggleCollapsedWithVal(false)
    }, true);

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      arrowLeft,
      arrowRight,
      vLogo
    }
  },

  data() {
    return {
      update: false,
      showCollapse: true,
    }
  },

}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

.box-img-nav {
  width: 144px;
  position: relative;
}
.box-img-nav img {
  width: 100%;
}
</style>
