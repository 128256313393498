<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <div :class="projectStatus == projectStatusTypeEnum.HOMOLOGACAO || projectStatus == projectStatusTypeEnum.TREINAMENTO ? 'margin-top-away-from-navbar' : ''" />
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { getProjectStatus } from "@/auth/utils";
import { projectStatusTypes } from "@/enum/projectStatusType";

export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    const projectStatus = getProjectStatus();

    const projectStatusTypeEnum = projectStatusTypes;

    return {
      routerTransition, contentWidth, projectStatus, projectStatusTypeEnum
    }
  },
}
</script>
<style lang="scss">
  .margin-top-away-from-navbar {
    margin-top: 70px;
  }
</style>
