<template>
    <b-modal
          id="change-unit"
          ref="unit"
          hide-footer
          centered
          header-class="bg-white"
      >
          <div class="d-flex flex-column align-items-center justify-content-center mb-3 text-center">
              
              <h2 class="text-center">Selecione a Unidade Operacional</h2>
              <span v-if="viewList">Caso deseja gerenciar a sua lista de unidades <a class="text-custom-blue" @click="redirectToUnitList">clique aqui.</a></span>
  
              <b-row class="col-12 mt-2">
                <b-col lg="12" v-for="unit in units" :key="unit.id_unidade">
                  <b-button
                      class="d-flex col-12 align-items-center"
                      variant="flat-dark"
                      @click="confirmAction(unit)"
                  >
                      <b-img
                        :src="require('@/assets/custom-icons/building-user-menu.svg')"
                        class="mr-3"
                        height="30"
                      />
                      <div class="d-flex flex-column justify-content-start">
                        <h3 class="text-left custom-gray">{{ unit.descricao }}</h3>
                      <span class="text-left text-secondary">{{ unit.regiao.descricao }}</span>
                      </div>
                  </b-button>
                </b-col>
              </b-row>
          </div>
      </b-modal>
  </template>
  
  <script>
  import { BModal, BButton, BLink, BRow, BCol, BImg } from 'bootstrap-vue';
import {actions, subjects} from "@/libs/acl/rules";

  
  export default {
    components: {
          BModal, BButton, BLink, BRow, BCol, BImg
      },
    data() {
      return {
        units: this.$store.state.sessions.userData.unidades,
        viewList: this.$can(actions.VISUALIZAR, subjects.DASHBOARD_UO)
      }
    },
    methods: {
      confirmAction(unit) {
              this.$store.commit(
                  "sessions/SET_USER_UNIT",
                  unit
              );
              this.$refs.unit.hide();
              this.redirectToHome();
      },
      redirectToHome(){
        this.$router.push({ 
            name: 'home'
        }).catch(() => {
          return;
        });
      },
      redirectToUnitList(){
        this.$router.push({ 
            name: 'unidade-list'
        }).catch(() => {
          this.$refs.unit.hide();
        });
  
        this.$refs.unit.hide();
      }
    },
  }
  </script>
  
  <style scoped>
      .bloco{
        height: 100px;
      }
  
      .custom-gray{
        color: #6e6b7b;
      }
      
      .list-enter-active,
      .list-leave-active {
        transition: all 0.5s ease;
      }
      .list-enter-from,
      .list-leave-to {
        opacity: 0;
        transform: translateX(30px);
      }
  
      .panel-enter-active,
      .panel-leave-active {
        transition: all 0.5s ease;
      }
      .panel-enter-from,
      .panel-leave-to {
        opacity: 0;
        height: 0;
        transform: translateX(30px);
      }
  </style>
  