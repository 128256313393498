<template>
  <b-modal
        id="change-company"
        ref="company"
        hide-footer
        centered
        header-class="bg-white"
    >
        <div class="d-flex flex-column align-items-center justify-content-center mb-3 text-center">
            
            <h2 class="text-center">Selecione uma empresa</h2>
            <span>Caso deseja gerenciar a sua lista de empresas <a class="text-custom-blue" @click="redirectToCompanyList">clique aqui.</a></span>

            <b-row class="col-12 mt-2">
              <b-col lg="12" v-if="companies.length > 1">
                <b-button
                      class="d-flex col-12 align-items-center"
                      variant="flat-dark"
                      @click="confirmAction(companyAll)"
                  >
                      <feather-icon icon="LayersIcon" size="30" class="mr-3 custom-gray"/>
                      <div class="d-flex flex-column justify-content-start">
                        <h3 class="text-left custom-gray">Todas as minhas empresas</h3>
                      </div>
                  </b-button>
              </b-col>
              <b-col lg="12" v-for="company in companies" :key="company.id_empresa">
                <b-button
                    class="d-flex col-12 align-items-center"
                    variant="flat-dark"
                    v-if="company.pessoa_juridica"
                    @click="confirmAction(company)"
                >
                    <b-img
                      :src="require('@/assets/custom-icons/building-user-menu.svg')"
                      class="mr-3"
                      height="30"
                    />
                    <div class="d-flex flex-column justify-content-start">
                      <h3 class="text-left custom-gray">{{ company.pessoa_juridica.nome_fantasia }}</h3>
                      <span class="text-left text-secondary">CNPJ: {{ company.pessoa_juridica.cnpj | VMask('##.###.###/####-##')}}</span>
                    </div>
                </b-button>
                <b-button
                  class="d-flex col-12 align-items-center"
                  variant="flat-dark"
                  v-if="company.pessoa_fisica"
                  @click="confirmAction(company)"
              >
                  <feather-icon icon="UserIcon" size="30" class="mr-3 custom-gray"/>
                  <div class="d-flex flex-column justify-content-start">
                    <h3 class="text-left custom-gray">{{ company.pessoa_fisica.nome }}</h3>
                    <span class="text-left text-secondary">CPF: {{ company.pessoa_fisica.cpf | VMask('###.###.###-##')}}</span>
                  </div>
              </b-button>
              </b-col>
            </b-row>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton, BLink, BRow, BCol, BImg } from 'bootstrap-vue';

export default {
  components: {
        BModal, BButton, BLink, BRow, BCol, BImg
    },
    props: {
      companies: {}
    },
  data() {
    return {
      companyAll: {
        nome: 'Todas as minhas empresas',
        all: true,
        id_empresa: false
      }
    }
  },
  methods: {
    confirmAction(company) {
            this.$store.commit(
                "sessions/SET_USER_COMPANY",
                company
            );
            this.$refs.company.hide();
            this.redirectToHome();
    },
    redirectToHome(){
      this.$router.push({ 
          name: 'home'
      }).catch(() => {
        return;
      });
    },
    redirectToCompanyList(){
      this.$router.push({ 
          name: 'empresa-list'
      }).catch(() => {
        this.$refs.company.hide();
      });

      this.$refs.company.hide();
    }
  },
}
</script>

<style scoped>
    .bloco{
      height: 100px;
    }

    .custom-gray{
      color: #6e6b7b;
    }
    
    .list-enter-active,
    .list-leave-active {
      transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }

    .panel-enter-active,
    .panel-leave-active {
      transition: all 0.5s ease;
    }
    .panel-enter-from,
    .panel-leave-to {
      opacity: 0;
      height: 0;
      transform: translateX(30px);
    }
</style>
